import React from "react";
import { graphql } from "gatsby";
import CoursesTemplate from "../templates/course-shop";

const environment = {
  lang: "nl",
  path: "/courses",
  altHrefLang: [
    {
      lang: "en",
      path: "/en/courses",
    },
    {
      lang: "nl",
      path: "/courses",
    },
  ],
  theme: "pink",
  seo: {
    title: "Video courses with quizzes, PDFs and QA by The Duth Online Academy",
    description:
      "In depth video courses developed by our experienced teachers after more than 5 years and 15000 students.",
  },
};

const Courses = (props) => {
  return <CoursesTemplate {...props} environment={environment} />;
};
export default Courses;

export const CoursesNLQuery = graphql`
  query coursesNL {
    courses: allContentfulCourses(filter: { node_locale: { eq: "nl" } }) {
      edges {
        node {
          ...CourseShopFragment
        }
      }
    }
  }
`;
